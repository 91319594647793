<template>
    <recuperadorCrud></recuperadorCrud>
</template>

<script>
import recuperadorCrud from '@/components/Recuperador/RecuperadorCrud';

export default {
    name: 'recuperador',
    data() {
        return {};
    },
    components: {
        recuperadorCrud
    }
};
</script>
